import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Tag from '../components/Views/Tag/Tag';
import getPostsByYear from '../utils/getPostsByYear';

type Props = {
  data: {
    allPost: any;
    allTag: any;
    [key: string]: any;
  };
  pageContext: any;
};

export const query = graphql`
  query($slug: String!) {
    allPost(
      sort: { fields: date, order: DESC }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        slug
        title
        tagLine
        date(formatString: "DD.MM.YYYY")
        tags {
          name
          slug
        }
      }
      group(field: year) {
        nodes {
          slug
          title
          year
          date(formatString: "DD.MM.YYYY")
          tags {
            name
            slug
          }
          icon
          pic {
            childImageSharp {
              resize(width: 348, height: 208, quality: 90) {
                src
              }
            }
            extension
            publicURL
          }
        }
      }
      totalCount
    }
    allTag(filter: { name: { eq: $slug } }) {
      nodes {
        name
        description
      }
    }
  }
`;

export default ({ data, pageContext }: Props) => {
  const {
    allPost,
    allPost: { group },
    allTag,
  } = data;
  const postsByYear = getPostsByYear(group);

  return (
    <Tag
      data={data}
      postsByYear={postsByYear}
      posts={allPost.nodes}
      tag={allTag.nodes[0]}
      pageContext={pageContext}
    />
  );
};
