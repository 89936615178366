import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../Layout/Layout';
import useSiteMetadata from '../../../utils/useSiteMetadata';
import ItemList from '../../ItemList/ItemList';
import TagsSection from '../../TagsSection/TagsSection';
import * as styles from '../../ItemList/itemList.module.css';

type TagProps = {
  data: {
    allPost: any;
    totalCount: number;
  };
  posts: {
    slug: string;
    title: string;
    date: string;
    tags: {
      name: string;
      slug: string;
    }[];
  }[];
  pageContext: {
    isCreatedByStatefulCreatePages: boolean;
    slug: string;
    name: string;
    [key: string]: any;
  };
  postsByYear: {
    [key: number]: any;
  };
  tag: {
    name: string;
    description: string;
  };
  totalCount: number;
};

const Tag = ({
  posts,
  pageContext,
  postsByYear,
  tag = {},
  data: {
    allPost: { totalCount },
  },
}: TagProps) => {
  const { tagsPath, basePath } = useSiteMetadata();

  return (
    <Layout
      currentPage={{
        name: 'tag',
        title: `${pageContext.name} projects`,
        footer: 'WebView HTML',
      }}
    >
      <div className={styles.listHeader}>
        <div className={styles.listTitle}>
          <div className={styles.listTitleText}>
            <span>{pageContext.name}</span> projects ({totalCount})
          </div>
          {/* tag.description ? (
            <span className={styles.listDesc}>//{tag.description}</span>
          ) : null */}
        </div>
        <TagsSection currentTagName={pageContext.name} />
      </div>
      <ItemList postsByYear={postsByYear} posts={posts} sx={{ mt: [4, 5] }} />
    </Layout>
  );
};

export default Tag;
